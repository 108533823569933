import CashBearLogo from '../assets/cashbear.png';
import CashMambaLogo from '../assets/cashmamba.webp';
import CashPantherLogo from '../assets/cashpanther.png';
import CashSamuraiLogo from '../assets/cashsamurai.webp';
import CashWarriorLogo from '../assets/cashwarrior.webp';
import CashZebraLogo from '../assets/cashzebra.png';
import MathsChampLogo from '../assets/mathschamp.webp';
import WolfEarnLogo from '../assets/wolfearn.webp';

export const latestGames = [
  //! Cash Zebra
  {
    name: 'Cash Zebra',
    description: 'Earn rewards with Cash Zebra! Complete tasks, Easy Surveys, and enjoy rewards!',
    link: 'https://play.google.com/store/apps/details?id=com.cashzebra.android',
    Image: CashZebraLogo,
  },

  //! Cash Mamba
  {
    name: 'Cash Mamba',
    description: 'Play games and complete simple surveys to earn with Cash Mamba!',
    link: 'https://play.google.com/store/apps/details?id=com.cashmamba.easyearning',
    Image: CashMambaLogo,
  },

  //! Cash Samurai
  {
    name: 'Cash Samurai',
    description: 'Play and earn rewards with Excitement!',
    link: 'https://play.google.com/store/apps/details?id=com.pocketfull.rewardpocket',
    Image: CashSamuraiLogo,
  },

  //! Wolf Earn
  {
    name: 'Wolf Earn',
    description: 'Enjoy and earn rewards with Wolf Earn!',
    link: 'https://play.google.com/store/apps/details?id=com.wolfearn.easyearning',
    Image: WolfEarnLogo,
  },

  //! Math Champ
  {
    name: 'Math Champ',
    description: 'Elevate your problem solving skills and get points!',
    link: 'https://play.google.com/store/apps/details?id=com.mathschamp.android',
    Image: MathsChampLogo,
  },

  //! Cash Warrior
  {
    name: 'Cash Warrior',
    description: 'Earn rewards by playing games and completing surveys with Cash Warrior!',
    link: 'https://play.google.com/store/apps/details?id=com.cashwarrior.easyearning',
    Image: CashWarriorLogo,
  },

  //! Cash Bear
  {
    name: 'Cash Bear',
    description: 'Play Games & Complete Simple Tasks To Earn Rewards With Cash Bear.',
    link: 'https://play.google.com/store/apps/details?id=com.cashbear.android',
    Image: CashBearLogo,
  },

  //! Cash Panther
  {
    name: 'Cash Panther',
    description: 'Complete Surveys & Simple Tasks To Earn Rewards With Cash Panther.',
    link: 'https://play.google.com/store/apps/details?id=com.cashpanther.android',
    Image: CashPantherLogo,
  },
]