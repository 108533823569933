import { availableApps } from './availableApps';

export const appRedirectionSchemas = {
  cashsamurai: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashsamurai),
  wolfearn: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.wolfearn),
  cashmamba: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashmamba),
  cashwarrior: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashwarrior),
  mathschamp: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.mathschamp),
  cashzebra: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashzebra),
  cashbear: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashbear),
  cashpanther: process.env.REACT_APP_GENERIC_PLAYSTORE_LINK.replace('APP_SCHEMA', availableApps.cashpanther),
}